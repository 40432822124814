import styled, {css} from 'styled-components';
import {Button} from '../Button/Button';
import React, {useEffect, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useClickInside} from '../../hooks/useClickInside';
import {Heading2} from '../Heading/Heading';
import {ReactComponent as Filter} from "../../assets/icons/filter2.svg";

const FilterButtonWrapper = styled.div`
    position: relative;
`;

const StyledFilterButton = styled(Button)`
    position: relative;
    background-color: var(--color-portal-button);
    color: var(--color-primary);
    min-width: 200px;
    
    &:hover { background-color: var(--color-portal-button-hover); }
    ${({$isOpen}) => $isOpen && css`background-color: var(--color-portal-button-hover); `};
`;

const StyledFilter = styled(Filter)`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

const FilterCount = styled.span`
    border-radius: 4px;
    padding: 0 6px;
    text-align: center;
    background-color: rgba(var(--color-blue-50-rgb), 0.1);
`;

const FlyOut = styled.div`
    width: 600px;
    position: absolute;
    top: 55px;
    right: 0;
    z-index: 2;
    border: 1px solid var(--color-grey-20);
    background-color: var(--color-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    box-shadow: var(--box-shadow);
    text-align: left;
    cursor: default;
`;

const Fields = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 30px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    
    span {
        min-width: 280px;
    }
`;

export const FilterButtonBase = ({children, isOpen, setIsOpen, handleApply, title}) => {
    const [searchParams,] = useSearchParams();

    // Close the fly out on click outside
    const insideRef = useRef(null);
    const insideFilterButton = useClickInside(insideRef);
    useEffect(() => {
        if(!insideFilterButton) { setIsOpen(false); }
        //eslint-disable-next-line
    }, [insideFilterButton]);

    return (
        <FilterButtonWrapper ref={insideRef}>
            <StyledFilterButton
                type="button"
                suffix={<></>}
                onClick={() => {
                    if(!isOpen) setIsOpen(true);
                    if(isOpen && insideFilterButton) setIsOpen(false);
                }}
                $isOpen={isOpen}
            >
                <StyledFilter />
                Filters
                {searchParams?.size > 0 &&
                    <FilterCount>
                        {searchParams.size}
                    </FilterCount>
                }
            </StyledFilterButton>

            {isOpen &&
                <FlyOut>
                    <Heading2 $noMargin>{title ?? "Filter op patiënten"}</Heading2>

                    <Fields>
                        {children}
                    </Fields>

                    <ButtonWrapper>
                        <Button as="span" onClick={handleApply}>Toepasssen</Button>
                    </ButtonWrapper>
                </FlyOut>
            }
        </FilterButtonWrapper>
    );
}