import React, {useState} from 'react';
import styled from 'styled-components';
import {Await, defer, useAsyncValue, useLoaderData, useNavigation, useParams, useRouteLoaderData} from 'react-router-dom';
import {Text} from '../../components/Text/Text';
import {Permissions} from '../../constants/enums';
import {checkRequiredAccount} from "../Root/Root";
import {fetchProtectedData, postData} from "../../api/fetch";
import {BackButton} from "../../components/BackButton/BackButton";
import {Heading1} from "../../components/Heading/Heading";
import qs from "qs";
import {Spinner, SpinnerOverlay, SpinnerWrapper} from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination";
import {RadioButton} from "../../components/Input/Input";
import {ReactComponent as LocationIcon} from '../../assets/icons/location-filled.svg';
import {AddPatientReservation} from './Partials/AddPatientReservation';
import {FilterButton} from './Partials/FilterButton';
import {AppliedFilterList} from '../../components/AppliedFilters/AppliedFilterList';
import PageHeadingContainer from '../../components/PageHeadingContainer/PageHeadingContainer';

const PageHeadingInnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
`;

const Buttons = styled.div`
    display: flex;
    gap: 20px;
`;

const ContactDetails = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const RelativeWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const DataRows = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const DataRow = styled.label`
    background-color: var(--color-white);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid ${({$checked}) => $checked ? 'var(--color-primary)' : 'var(--color-grey-20)'};
    box-shadow: var(--box-shadow) ${({$checked}) => $checked && ', 0 0 0 1px var(--color-primary)'};
    cursor: pointer;
    display: flex;
    gap: 24px;
`;

const DataDate = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-basis: 320px;
`;

const Location = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: auto;
`;

const LocationFilledIcon = styled(LocationIcon)`
    flex-shrink: 0;
    width: 24px;
    height: 24px;
`;

const StyledBackButton = styled(BackButton)`
    margin-top: 10px;
    margin-bottom: 10px;
`;

const InlineSpinner = styled(Spinner)`
    width: 16px;
    height: 16px;
`;

const FallbackText = styled(Text).attrs({
    $noMargin: true,
})`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export async function reserveAppointmentAction({request, params}){
    let formData = await request.json()
    let intent = formData.intent
    switch(intent) {
        case "add-reservation": {
            delete formData.intent
            return await postData(request, `reservation/patient/${params.patientUUID}/reserve`, JSON.stringify(formData));
        }
        default:
            return {"default": true};
    }
}

export async function reserveAppointmentLoader({request, params}){
    await checkRequiredAccount(Permissions.APPOINTMENT_CREATE);

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search)

    const page = searchParams.get("page") ?? 1;
    const weekday = searchParams.getAll("weekday") ?? null;
    const month = searchParams.getAll("month") ?? null;
    const location = searchParams.getAll("location") ?? null;

    const queryString = qs.stringify({
        ...(page && {page}),
        ...(weekday && {weekday}),
        ...(month && {month}),
        ...(location && {location})
    }, { arrayFormat: 'comma' });

    const reservationDataPromise = fetchProtectedData(request,`reservation/patient/${params.patientUUID}/appointments${queryString ? `?${queryString}` : ""}`);
    const patientDataPromise = fetchProtectedData(request,`patient/${params.patientUUID}`);

    return defer({reservationDataPromise, patientDataPromise});
}

export const ReserveAppointment = () => {
    const {locationData} = useRouteLoaderData("portal");
    const {reservationDataPromise, patientDataPromise} = useLoaderData()
    const [selected, setSelected] = useState(null);
    const {patientUUID} = useParams();

    return (
        <>
            <PageHeadingContainer $flexDirection="column" $alignItems="start">
                <StyledBackButton to={`/patient/${patientUUID}/afspraken`} />
                <PageHeadingInnerContainer>
                    <Heading1>Afspraak reserveren</Heading1>
                    <Buttons>
                        <AddPatientReservation
                            selected={selected}
                            onAfterSubmit={() => setSelected(null)}
                        />
                        <FilterButton
                            locationData={locationData}
                            onAfterSubmit={() => setSelected(null)}
                        />
                    </Buttons>
                </PageHeadingInnerContainer>

                <React.Suspense fallback={
                    <ContactDetails>
                        <FallbackText><strong>Naam:</strong><InlineSpinner /></FallbackText>
                        <FallbackText><strong>Medicore ID:</strong><InlineSpinner /></FallbackText>
                    </ContactDetails>
                }>
                    <Await resolve={patientDataPromise} errorElement={<></>}>
                        {(data) => {
                            return (
                                <ContactDetails>
                                    <Text $noMargin><strong>Naam:</strong> {data?.name && data.name}</Text>
                                    <Text $noMargin><strong>Medicore ID:</strong> {data?.medicoreID && data.medicoreID}</Text>
                                </ContactDetails>
                            );
                        }}
                    </Await>
                </React.Suspense>

                <AppliedFilterList locationData={locationData} />
            </PageHeadingContainer>

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={reservationDataPromise} errorElement={<Text $error>Er is iets misgegaan, probeer het opnieuw.</Text>}>
                    <DeferredReserveAppointment selected={selected} setSelected={setSelected} />
                </Await>
            </React.Suspense>
        </>
    );
}

function DeferredReserveAppointment({selected, setSelected}) {
    const reservationData = useAsyncValue();
    const navigation = useNavigation();
    const {patientUUID} = useParams();
    const isPending = navigation?.location?.pathname?.includes(`/patient/${patientUUID}/afspraak-reserveren`);

    const selectAppointment = (e) => {
        setSelected(e.target.value);
    }

    return (
        <RelativeWrapper>
            {reservationData?.items?.length > 0 ? (
                <>
                    <Pagination searchResults={reservationData?.items} $position="top" />
                    <DataRows>
                        {reservationData?.items?.map(item => {
                            return (
                                <DataRow key={item.id} $checked={selected === item.id}>
                                    <RadioButton
                                        name="appointment"
                                        type="radio"
                                        value={item.id}
                                        checked={selected === item.id}
                                        onChange={selectAppointment}
                                    />
                                    <DataDate>
                                        <Text $noMargin $bold>{item.date}</Text>
                                        <Text $noMargin>{item.time}</Text>
                                    </DataDate>
                                    <Location>
                                        <LocationFilledIcon />
                                        <Text $noMargin>{item.location?.name}</Text>
                                    </Location>
                                </DataRow>
                            );
                        })}
                    </DataRows>
                    <Pagination searchResults={reservationData?.items} />
                </>
            ) : (
                <Text>Geen resultaten gevonden</Text>
            )}

            {isPending &&
                <SpinnerOverlay>
                    <SpinnerWrapper>
                        <Spinner/>
                    </SpinnerWrapper>
                </SpinnerOverlay>
            }
        </RelativeWrapper>
    );
}