import {checkRequiredAccount} from "../Root/Root";
import {Permissions} from "../../constants/enums";
import {Await, defer, useLoaderData, useParams, useRouteLoaderData} from "react-router-dom";
import {deleteData, fetchProtectedData} from "../../api/fetch";
import {useOpenModal} from "../../hooks/useOpenModal";
import {checkAccountPermissions} from "../../utils/helpers";
import React, {useState} from "react";
import {Spinner} from "../../components/Spinner/Spinner";
import {Text} from "../../components/Text/Text";
import {Table, TableHead, TableHeadCell, TableHeadRow, TableRow, TableRowButton, TableRowCell, TableRowInner, TrashIcon} from "../../components/Table/Table";
import dayjs from "dayjs";
import styled from "styled-components";
import {DeleteReservation} from "./Partials/DeleteReservation";
import {Button} from "../../components/Button/Button";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Tooltip from "../../components/Tooltip/Tooltip";

const Header = styled.div`
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

dayjs.extend(customParseFormat)

export async function appointmentsAction({request}){
    let formData = await request.formData();
    let intent = formData.get("intent");

    switch(intent) {
        case "delete-reservation": {
            const participantId = formData.get("participantId");

            return await deleteData(request, `reservation/${participantId}`);
        }

        default:
            return {"default": true};
    }
}

export async function appointmentsLoader({request, params}){
    await checkRequiredAccount(Permissions.APPOINTMENT_VIEW);

    const appointmentPromise = fetchProtectedData(request,`patient/${params.patientUUID}/appointments`);

    return defer({appointmentPromise});
}

const Appointments = () => {
    const {account} = useRouteLoaderData("root");
    const {appointmentPromise} = useLoaderData();
    const {isOpen, handleOpen, handleClose} = useOpenModal();
    const [targetId, setTargetId] = useState(null);
    const {patientUUID} = useParams()

    const handleDeleteButton = (id) => {
        setTargetId(id);
        handleOpen();
    }

    return (
        <>
            {checkAccountPermissions(account, Permissions.APPOINTMENT_CREATE) &&
                <Header>
                    <Button to={`/patient/${patientUUID}/afspraak-reserveren`}>Afspraak reserveren</Button>
                </Header>
            }

            <React.Suspense fallback={
                <SpinnerWrapper>
                    <Spinner />
                </SpinnerWrapper>
            }>
                <Await resolve={appointmentPromise} errorElement={<Text>De afspraken kunnen niet worden opgehaald. Probeer het opnieuw.</Text>}>
                    {(appointmentData) => {
                        if(appointmentData.length === 0) return <Text>Er zijn nog geen afspraken gereserveerd.</Text>

                        return (
                            <>
                                <Table>
                                    <TableHead>
                                        <TableHeadRow>
                                            <TableHeadCell>Datum</TableHeadCell>
                                            <TableHeadCell>Status</TableHeadCell>
                                            <TableHeadCell>Locatie</TableHeadCell>
                                            <TableHeadCell>Gereserveerd op</TableHeadCell>
                                            <TableHeadCell $flex="0" $padding="0 0 15px 0" $minWidth="48px"></TableHeadCell>
                                        </TableHeadRow>
                                    </TableHead>

                                    {appointmentData.map((item, index) => {
                                        const isOddRow = index % 2 === 0;

                                        const date = dayjs(item.date, "DD/MM/YYYY").format("DD-MM-YYYY");
                                        const reservedAt = item?.reservation?.reservedAt && dayjs(item?.reservation?.reservedAt, "DD/MM/YYYY").format("DD-MM-YYYY");

                                        return (
                                            <TableRow key={`${date}-${index}`}>
                                                <TableRowInner $isOddRow={isOddRow}>
                                                    <TableRowCell>{date}</TableRowCell>
                                                    <TableRowCell>{item.reservation?.status}</TableRowCell>
                                                    <TableRowCell>{item.location?.city}</TableRowCell>
                                                    <TableRowCell>{reservedAt}</TableRowCell>
                                                    <TableRowCell $flex="0" $padding="16px 0" $minWidth="48px">
                                                        {(checkAccountPermissions(account, Permissions.APPOINTMENT_DELETE)) &&
                                                            <TableRowButton disabled={item.reservation?.status !== "Gereserveerd"} onClick={() => handleDeleteButton(item.reservation?.reservedBy?.participantId)}><TrashIcon /></TableRowButton>
                                                        }
                                                    </TableRowCell>
                                                </TableRowInner>
                                            </TableRow>
                                        );
                                    })}
                                </Table>

                                <DeleteReservation isOpen={isOpen} handleClose={handleClose} item={targetId} />
                            </>
                        );
                    }}
                </Await>
            </React.Suspense>
        </>
    );
}

export default Appointments;