import styled from "styled-components";
import {AppliedFilter} from './AppliedFilter';
import React from 'react';
import {useSearchParams} from 'react-router-dom';
import dayjs from 'dayjs';
import {monthOptions, weekdayOptions} from '../../utils/dateOptions';

const StyledAppliedFilterList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const AppliedFilterList = ({healthInsuranceData, locationData}) => {
    let [searchParams, setSearchParams] = useSearchParams();

    // Retrieve the filters from searchParams
    const nextAppointmentFrom = searchParams.get("nextAppointmentFrom");
    const nextAppointmentUntil = searchParams.get("nextAppointmentUntil");
    const healthInsurance = searchParams.getAll("healthInsurance");
    const hasUncompletedQuestionnaires = searchParams.get("hasUncompletedQuestionnaires");
    const registeredFrom = searchParams.get("registeredFrom");
    const registeredUntil = searchParams.get("registeredUntil");
    const location = searchParams.getAll("location");
    const fullName = searchParams.get("fullName");
    const medicoreID = searchParams.get("medicoreID");
    const birthDate = searchParams.get("birthDate");
    const reservedFrom = searchParams.get("reservedFrom");
    const reservedUntil = searchParams.get("reservedUntil");
    const weekday = searchParams.getAll("weekday");
    const month = searchParams.getAll("month");

    // Delete/overwrite the filters so page will get removed
    function handleDeleteFilter(key, removeVal) {
        const newParams = {
            ...(nextAppointmentFrom ? {nextAppointmentFrom} : {}),
            ...(nextAppointmentUntil ? {nextAppointmentUntil} : {}),
            ...(healthInsurance ? {healthInsurance} : {}),
            ...(hasUncompletedQuestionnaires ? {hasUncompletedQuestionnaires} : {}),
            ...(registeredFrom ? {registeredFrom} : {}),
            ...(registeredUntil ? {registeredUntil} : {}),
            ...(location ? {location} : {}),
            ...(fullName ? {fullName} : {}),
            ...(medicoreID ? {medicoreID} : {}),
            ...(birthDate ? {birthDate} : {}),
            ...(reservedFrom ? {reservedFrom} : {}),
            ...(reservedUntil ? {reservedUntil} : {}),
            ...(weekday ? {weekday} : {}),
            ...(month ? {month} : {}),
        }
        if (removeVal === undefined) {
            delete newParams[key];
        } else {
            newParams[key] = newParams[key]?.filter(fv => fv !== removeVal)
        }
        setSearchParams(newParams, { replace: true, preventScrollReset: true });
    }

    return (
        <StyledAppliedFilterList>
            {nextAppointmentFrom && <AppliedFilter title={'Eerstkomende afspraak vanaf'} value={dayjs(nextAppointmentFrom).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("nextAppointmentFrom")} />}
            {nextAppointmentUntil && <AppliedFilter title={'Eerstkomende afspraak vóór'} value={dayjs(nextAppointmentUntil).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("nextAppointmentUntil")} />}
            {registeredFrom && <AppliedFilter title={'Aangemeld vanaf'} value={dayjs(registeredFrom).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("registeredFrom")} />}
            {registeredUntil && <AppliedFilter title={'Aangemeld vóór'} value={dayjs(registeredUntil).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("registeredUntil")} />}
            {healthInsurance?.length > 0 &&
                healthInsurance?.map(item => {
                    const currentHealthInsurance = healthInsuranceData?.find(hid => hid?.id?.toLowerCase() === item)
                    if (!currentHealthInsurance) return null;

                    return <AppliedFilter key={currentHealthInsurance.id} title={'Zorgverzekeraar'} value={currentHealthInsurance?.name} onRemove={() => handleDeleteFilter("healthInsurance", currentHealthInsurance?.id?.toLowerCase())}/>
                })
            }
            {location?.length > 0 &&
                location?.map((l) => {
                    const loc = locationData.find(ld => ld.id === l)
                    if (!loc) return null;

                    return <AppliedFilter key={l} title={"Locatie"} value={loc.name} onRemove={() => handleDeleteFilter("location", loc.id)}/>
                })
            }
            {hasUncompletedQuestionnaires && <AppliedFilter title={'Openstaande vragenlijsten'} value={hasUncompletedQuestionnaires === "1" ? "Ja" : hasUncompletedQuestionnaires === "0" ? "Nee" : ""} onRemove={() => handleDeleteFilter("hasUncompletedQuestionnaires")} />}
            {fullName && <AppliedFilter title="Naam" value={fullName} onRemove={() => handleDeleteFilter("fullName")} />}
            {medicoreID && <AppliedFilter title="Medicore ID" value={medicoreID} onRemove={() => handleDeleteFilter("medicoreID")} />}
            {birthDate && <AppliedFilter title="Geboortedatum" value={dayjs(birthDate).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("birthDate")} />}
            {reservedFrom && <AppliedFilter title="Aangemeld vanaf" value={dayjs(reservedFrom).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("reservedFrom")} />}
            {reservedUntil && <AppliedFilter title="Aangemeld vóór" value={dayjs(reservedUntil).format("DD-MM-YYYY")} onRemove={() => handleDeleteFilter("reservedUntil")} />}
            {weekday?.length > 0 &&
                weekday?.map(item => {
                    const currentWeekday = weekdayOptions?.find(wd => wd.value === item);
                    if (!currentWeekday) return null;

                    return <AppliedFilter key={currentWeekday.id} title="Weekdag" value={currentWeekday.label} onRemove={() => handleDeleteFilter("weekday", currentWeekday.value)} />
                })
            }
            {month?.length > 0 &&
                month?.map(item => {
                    const currentMonth = monthOptions?.find(m => m.value === item);
                    if (!currentMonth) return null;

                    return <AppliedFilter key={currentMonth.id} title="Maand" value={currentMonth.label} onRemove={() => handleDeleteFilter("month", currentMonth.value)} />
                })
            }
        </StyledAppliedFilterList>
    );
}